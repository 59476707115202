import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons'
import { Col, Layout, Row, Space, Typography, Button } from 'antd'
import React, { FC } from 'react'

const { Footer } = Layout
const { Title } = Typography

const FooterWrapper: FC = () => (
  <Footer className="shadow-sm bg-light">
    <Row align="middle" justify="space-between">
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Button
          type="link"
          href="https://www.facebook.com/pitapitakilongbeach"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookOutlined style={{ fontSize: '32px' }} />
        </Button>
        <Button
          type="link"
          href="https://www.instagram.com/pitapitaki/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramOutlined style={{ fontSize: '32px' }} />
        </Button>
      </Col>

      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Title level={4} style={{ textAlign: 'start' }}>
          ©{new Date().getFullYear()} PITA PITAKI
        </Title>
      </Col>
    </Row>
  </Footer>
)
export default FooterWrapper

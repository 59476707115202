import React, { FC } from 'react'
import { Layout, Grid, Affix } from 'antd'
import styled from 'styled-components'

import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const { useBreakpoint } = Grid

const StyledHeader = styled(Layout.Header)`
  background-color: white;
  height: auto;
  line-height: normal;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
`
const HeaderWrapper: FC = () => {
  const { md, lg, xl, xxl } = useBreakpoint()

  return (
    <Affix>
      <StyledHeader id="header">
        {md || lg || xl || xxl ? <DesktopMenu /> : <MobileMenu placement="right" />}
      </StyledHeader>
    </Affix>
  )
}

export default HeaderWrapper

import theme from 'styled-theming'

const backgroundColor = theme('mode', {
  light: '#ffffff',
  dark: '#000000',
})

const color = theme('mode', {
  light: '#000000',
  dark: '#ffffff',
})

const primaryColor = theme('mode', {
  light: '#4e90ff',
  dark: '#4e90ff',
})

const lightBackground = theme('mode', {
  light: '#f0f2f5',
  dark: '#f0f2f5',
})

export { backgroundColor, color, primaryColor, lightBackground }

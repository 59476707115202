import React, { FC } from 'react'
import { Link } from 'gatsby'
import { Button, Space, Image, Row, Col, Menu } from 'antd'
import { PhoneOutlined } from '@ant-design/icons'

import FlexContainer from '@root/ic-ui-react/FlexContainer'

import Logo from '../images/logo.png'

const DesktopMenu: FC = () => (
  <Row align="middle">
    <Col span={6}>
      <FlexContainer justifyContent="start">
        <Link to="/">
          <Image src={Logo} width="75px" height="75px" />
        </Link>
      </FlexContainer>
    </Col>

    <Col span={12}>
      <Menu
        mode="horizontal"
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          lineHeight: 'normal',
          borderBottom: 'none',
        }}
      >
        <Menu.Item>
          <Link to="/" activeStyle={{ color: '#4e90ff', fontWeight: '500' }}>
            <strong>HOME</strong>
          </Link>
        </Menu.Item>

        <Menu.Item>
          <Link to="/about" activeStyle={{ color: '#4e90ff', fontWeight: '500' }}>
            <strong>ABOUT</strong>
          </Link>
        </Menu.Item>

        <Menu.Item>
          <Link to="/menu" activeStyle={{ color: '#4e90ff', fontWeight: '500' }}>
            <strong>MENU</strong>
          </Link>
        </Menu.Item>

        <Menu.Item>
          <Link to="/menu/catering" activeStyle={{ color: '#4e90ff', fontWeight: '500' }}>
            <strong>CATERING</strong>
          </Link>
        </Menu.Item>

        <Menu.Item>
          <Link to="/menu/special" activeStyle={{ color: '#4e90ff', fontWeight: '500' }}>
            <strong>SPECIAL DEAL</strong>
          </Link>
        </Menu.Item>

        <Menu.Item>
          <Link to="/contact-us" activeStyle={{ color: '#4e90ff', fontWeight: '500' }}>
            <strong>CONTACT US</strong>
          </Link>
        </Menu.Item>
      </Menu>
    </Col>

    <Col span={6}>
      <FlexContainer justifyContent="flex-end">
        <Button type="link" href="https://www.toasttab.com/pitapitaki/v3">
          ORDER ONLINE
        </Button>
        <Button type="primary" shape="round" icon={<PhoneOutlined />} size="small">
          ORDER NOW
        </Button>
      </FlexContainer>
    </Col>
  </Row>
)

export default DesktopMenu

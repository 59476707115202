import React, { FC, useCallback, useState } from 'react'
import { Drawer, Menu, Typography, Button, Row, Col, Image, Space } from 'antd'
import { Link } from 'gatsby'
import { PhoneOutlined, MenuOutlined } from '@ant-design/icons'

import Logo from '../images/logo.png'

const MobileMenu: FC<{ placement: 'top' | 'bottom' | 'right' | 'left' }> = ({ placement }) => {
  const [visible, setVisible] = useState(false)

  const handleToggleMenu = useCallback(() => {
    setVisible((visible) => !visible)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{
        backgroundColor: 'white',
      }}
    >
      <Col span={18}>
        <Link to="/">
          <Image src={Logo} width="50px" height="50px" />
        </Link>
      </Col>

      <Col span={6}>
        <Button type="text" icon={<MenuOutlined />} onClick={handleToggleMenu} block>
          MENU
        </Button>
      </Col>

      <Drawer
        title={
          <>
            <Link to="/">
              <Image src={Logo} width="150px" height="150px" />
              <Typography.Paragraph>Authentic Greek Cuisine</Typography.Paragraph>
            </Link>

            <Space wrap>
              <Button
                type="primary"
                icon={<PhoneOutlined size={18} />}
                href="tel:5624240446"
                size="small"
              >
                CALL NOW
              </Button>{' '}
              <Button type="link" size="small" href="https://www.toasttab.com/pitapitaki/v3">
                ORDER ONLINE
              </Button>
            </Space>
          </>
        }
        placement={placement}
        onClose={handleCloseMenu}
        visible={visible}
        key={placement}
        width="75%"
        height="auto"
      >
        <Menu style={{ width: '100%' }} mode="vertical">
          <Menu.Item key="/">
            <Link to="/" activeStyle={{ color: '#4e90ff', fontWeight: '500' }}>
              <strong>HOME</strong>
            </Link>
          </Menu.Item>

          <Menu.Item key="about">
            <Link to="/about" activeStyle={{ color: '#4e90ff', fontWeight: '500' }}>
              <strong>ABOUT</strong>
            </Link>
          </Menu.Item>

          <Menu.Item>
            <Link to="/menu" activeStyle={{ color: '#4e90ff', fontWeight: '500' }}>
              <strong>MENU</strong>
            </Link>
          </Menu.Item>

          <Menu.Item>
            <Link to="/menu/catering" activeStyle={{ color: '#4e90ff', fontWeight: '500' }}>
              <strong>CATERING</strong>
            </Link>
          </Menu.Item>

          <Menu.Item>
            <Link to="/menu/special" activeStyle={{ color: '#4e90ff', fontWeight: '500' }}>
              <strong>SPECIAL</strong>
            </Link>
          </Menu.Item>

          <Menu.Item key="contact-us">
            <Link to="/contact-us" activeStyle={{ color: '#4e90ff', fontWeight: '500' }}>
              <strong>CONTACT</strong>
            </Link>
          </Menu.Item>
        </Menu>
      </Drawer>
    </Row>
  )
}

export default MobileMenu

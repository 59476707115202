import { FC, createContext, useState, useContext } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

const ThemeContext = createContext<ThemeManager>({} as ThemeManager)

const defaultTheme: Theme = {
  mode: 'light',
}

interface Theme {
  mode: 'light' | 'dark'
}

interface ThemeManager {
  changeTheme(): void
  theme: Theme
}

const ThemeProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(defaultTheme)

  const manager = {
    changeTheme() {
      setTheme((t) => ({ ...t, mode: t.mode === 'light' ? 'dark' : 'light' }))
    },
    theme,
  }

  return (
    <ThemeContext.Provider value={manager}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  )
}

function useTheme(): ThemeManager {
  return useContext(ThemeContext)
}

export type { Theme }
export { ThemeProvider, useTheme }
